html,
body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    width: 100%;
}

* {
    box-sizing: border-box;
}

#root {
    height: 100%;
    width: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.MuiExpansionPanel-root:before {
    height: 0 !important;
}

[id*='editor-'] iframe {
    min-width: 100% !important;
}
